import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import Helmet from 'react-helmet';

import returnSortedNavigation from '@accrosoft-ltd/accropress-theme-jane/src/components/returnSortedNavigation';

import Translate from '@accrosoft-ltd/accropress-websites-components/dist/esm/translateWidget';
import HeaderMenuBarNested from '@accrosoft-ltd/accropress-websites-components/dist/esm/HeaderMenuBarNested';
import Dropdown from '@accrosoft-ltd/accro-multi-level-dropdown';
import { ReactNestedMenu } from 'react-nested-menu';

const RenderChildLinkHTML = ({ child, i }) => {
  return (
    <Dropdown.Item className="dropdown-item link" key={i}>
      {child.children && <span className="fas fa-angle-right"></span>}
      <div
        onClick={() => {
          navigate(
            child.parentSlug ? child.parentSlug + child.slug : child.slug
          );
        }}
      >
        <span>{child.title}</span>
      </div>

      {child.children && child.children.length > 0 && (
        <Dropdown.Submenu
          menuClassName="dropdown-submenu"
          className="dropdown-submenu"
        >
          {child.children.map((childtwo, itwo) => (
            <RenderChildLinkHTML child={childtwo} key={itwo} />
          ))}
        </Dropdown.Submenu>
      )}
    </Dropdown.Item>
  );
};

const RenderChildLinks = ({ item }) => {
  return (
    <>
      {item.children && item.children.length > 0 && (
        <>
          {item.children.map((child, i) => (
            <RenderChildLinkHTML key={i} child={child} />
          ))}
        </>
      )}
    </>
  );
};

const ReturnChildNavItems = ({ WebsiteNavData }) => {
  return (
    <>
      {WebsiteNavData && WebsiteNavData.length > 0 ? (
        WebsiteNavData.map((item, i) => (
          <Dropdown
            key={i}
            menuClassName="dropdown-child-nav"
            wrapperClassName="dropdown-wrapper-nav"
            buttonClassName="dropdown-parentLink"
            className="dropdown-child-nav"
            isActive={true}
            title={item.title}
            onClick={() =>
              navigate(
                item.parentSlug ? item.parentSlug + item.slug : item.slug
              )
            }
          >
            <RenderChildLinks item={item} i={i} />
          </Dropdown>
        ))
      ) : (
        <li className="loading-nav"></li>
      )}
    </>
  );
};

const NestedNavSingleLevel = ({ item }) => {
  const { id, parentSlug, slug, title, parentId } = item;

  const combinedSlugCurrentPage = parentSlug + slug;

  return (
    <div className={`nested-menu-link-wrap`}>
      <Link
        to={parentSlug ? parentSlug + slug : slug}
        className={`nested-menu-link-link ${
          parentId ? 'childLink' : 'parentLink'
        }`}
      >
        <span>{title}</span>
      </Link>
    </div>
  );
};

const NestedNavMultiLevel = ({ item }) => {
  const { id, parentSlug, slug, title, parentId } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
      setOpen(!open);
  };

  const combinedSlugCurrentPage = parentSlug + slug;

  return (
    <div className={`nested-menu-link-wrap ${open ? 'active' : 'inactive'}`}>
      <Link
        to={parentSlug ? parentSlug + slug : slug}
        className={`nested-menu-link-link ${
          parentId ? 'childLink' : 'parentLink'
        } ${open ? 'active' : 'inactive'}`}
      >
        <span>{title}</span>
      </Link>

      <div className="nav-child-drop-trigger" onClick={() => handleClick()}>
        <span
          className={`${
            open ? 'active fas fa-angle-up' : 'inactive fas fa-angle-down'
          }`}
        ></span>
      </div>
    </div>
  );
};

const linkTransformer = (menuItem) => {
  return (
    <>
      {menuItem.children ? (
        <NestedNavMultiLevel item={menuItem} />
      ) : (
        <NestedNavSingleLevel item={menuItem} />
      )}
    </>
  );
};

const Header = (props) => {
  const [scrolled, setScrolled] = useState(false);

  const [toggleMobileNav, setToggleMobileNav] = useState(false);

  const [websiteNavData, setWebsiteNavData] = useState([]);

  const [websiteNavLoading, setWebsiteNavLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const [searchExpanded, setSearchExpanded] = useState(false);

  const searchOnClick = function (e) {
    if (searchExpanded) {
      setSearchExpanded(false);
    } else {
      e.preventDefault();
      setSearchExpanded(true);
    }
  };

  const accropressWebsiteNavigation = useStaticQuery(graphql`
    query {
      allAccroPress {
        totalCount
        nodes {
          menu {
            id
            slug
            parentSlug
            title
            order
            isPublished
            children {
              id
              order
              isPublished
              parentId
              slug
              parentSlug
              title
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    returnSortedNavigation({
      websiteNavJSON: props.navResultData || accropressWebsiteNavigation,
      fromGQL: props.navResultData ? false : true,
      setWebsiteNavData,
      websiteNavData,
      setWebsiteNavLoading: setWebsiteNavLoading,
      websiteNavLoading,
    });
  }, [websiteNavLoading, accropressWebsiteNavigation]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window && window.scrollY === 0) {
        setScrolled(false);
      } else if (window && window.scrollY > 0) {
        setScrolled(true);
      }
    };

    if (window) {
      if (window.innerWidth <= 1190) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      window.addEventListener('resize', function () {
        if (window.innerWidth <= 1190) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      });

      window.addEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href="/favicon.ico" />
        <title>{props.gatsbyConfigSiteMetaData.title}</title>
      </Helmet>
      <header
        className={'header ' + (scrolled === true ? 'scrolled' : '')}
        id="header"
      >
        <div className="container">
          <div className="logo header">
            <Link to="/">
              <img
                src={
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload.length > 0 &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload[0] &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload[0].children
                    ? props.gatsbyConfigPluginsAPOptions.siteSettings.themeSettingsPayload[0].children.find(
                        (o) => o.id === 'logo-image'
                      ).value
                    : props.gatsbyConfigPluginsAPOptions.siteSettings.theme.themeSettingsDefinition[0].children.find(
                        (o) => o.id === 'logo-image'
                      ).default
                }
                alt={
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload.length > 0 &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload[0] &&
                  props.gatsbyConfigPluginsAPOptions.siteSettings
                    .themeSettingsPayload[0].children
                    ? props.gatsbyConfigPluginsAPOptions.siteSettings.themeSettingsPayload[0].children.find(
                        (o) => o.id === 'school-name-title'
                      ).value
                    : props.gatsbyConfigPluginsAPOptions.siteSettings.theme.themeSettingsDefinition[0].children.find(
                        (o) => o.id === 'school-name-title'
                      ).default
                }
              />
            </Link>
          </div>
          <div className="website-nav-wrap">
            <div className="website-nav-inner-wrap">
              <ul className="search-bar">
                <li className="googleTranslations">
                  <Translate />
                </li>
                <li className="weduc">
                  <a className="linked_logo weduc_logo" href="/weduc-login">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      viewBox="0 0 1177.6 387.6"
                      xmlSpace="preserve"
                    >
                      <path
                        class="st0"
                        fill="#01b1af"
                        d="M1149.8 304c-7.3 0-14.7 2.3-20.8 6.8-9.6 6.8-24.3 16.9-48.5 16.9-38.9 0-61.5-31.6-61.5-69.4s22.5-69.4 61.5-69.4c16.9 0 30.5 4.5 42.9 11.8 6.2 3.4 13 5.1 19.2 5.1 9.6 0 18.6-4 24.8-11.9 3.4-4.5 5.7-10.1 5.7-15.8 0-8.4-4-16.9-12.4-24.2-20.3-17.5-49.6-24.8-80.1-24.8-35.1 0-66.9 14.2-90.3 37v101.7c0 17.8-3.6 34.6-9.9 50-1.9 4.5-3.9 9-6.3 13.2 23.3 33.9 62.4 56.3 106.5 56.3 33.3 0 71-14.1 87.4-32.1 6.2-6.7 9.6-15.2 9.6-23.1 0-6.8-2.3-13-6.8-18.6-5.8-6.1-13.1-9.5-21-9.5M977.8 202.6v-37.3c0-1.2-.2-2.4-.4-3.6-1.9-16.9-16.6-30.2-34-30.2-18.6 0-33.8 15.2-33.8 33.8v109.4c0 28.7-21.4 51.9-52.4 51.9s-51.9-23.1-51.9-51.9V165.2c0-18.4-15.4-33.4-33.8-33.7v219.3c0 .4 0 .7-.1 1.1 21.8 22 52.1 35.6 85.7 35.6 43.2 0 81-22.4 102.4-56.2 2.5-4 4.8-8.2 6.9-12.6 2.4-5.1 4.5-10.3 6.2-15.7 3.4-11.1 5.3-22.8 5.3-35.1l-.1-65.3zM725.3 0c-18.6 0-33.8 15.2-33.8 33.8v130.3c-2.5-4.8-6.9-9.8-12.3-14.4-13.2-11.2-33.6-20.5-55.9-20.5-37.6 0-70.4 17.2-92.5 44.2 2.5 3.5 4.8 7.2 6.9 11 12 21.3 18.8 46.5 18.8 74 .1 10.1-4.6 19.3-11.5 25.2-6.5 5.6-14.8 8.7-23.5 9 3.3 1.7 6.5 3.8 9.4 6.3l.1.1c9 8.4 14.6 19.8 14.7 32.2-.1 3.3-.7 6.2-1.6 8.8-1 2.8-2.3 5.4-3.7 7.8-.8 1.3-1.7 2.4-2.5 3.6 21.8 22.3 51.6 36.2 85.4 36.2 24.1 0 46.1-9.2 60-22.8 4.3-4.2 7.9-8.9 10.5-13.8 0 6.4 1.5 11.8 3.7 16.5 5.8 11.8 17.4 18 27.9 18 16.8 0 30.7-12.9 33.3-29.1.3-1.8.5-3.5.5-5.3V33.9C759.1 15.2 743.9 0 725.3 0M569.1 258.3c0-37.8 22-68.2 60.9-68.2s61.5 30.4 61.5 68.2-22.6 68.2-61.5 68.2c-39 0-60.9-30.5-60.9-68.2"
                      ></path>
                      <path
                        class="st0"
                        fill="#01b1af"
                        d="M524.4 310.1c-.6-.7-1.2-1.5-1.9-2.1-4.5-4-9.7-6.5-15-7.5-1.4-.2-2.8-.4-4.2-.4-2.5 0-5.4.6-8.4 1.6-6.3 2.1-13.2 6-18.5 10.2-13 10.2-29.3 18.1-53 18.1-28.4 0-50.1-14.8-58.1-37.3-1.4-3.9-2.4-8-2.9-12.3H519.9c12.9 0 24.2-9 24.2-22 0-23.1-5.1-44.2-14.1-62.4-2.1-4.2-4.4-8.3-6.9-12.2-2.4-3.7-5-7.2-7.7-10.5-22.3-27.5-56-44.1-95.3-44.1-25.2 0-48.5 7.4-68.2 20 1.4 4.2 2.3 8.6 2.3 13.2v.1c0 6.3-1.6 11.4-2.9 15.3l-.1.4L308.9 290l-6.8 18.1c19.4 46.6 65.2 79.5 118.7 79.5 34.7 0 72.7-10.6 100.8-36.1.2-.2.5-.4.7-.6 2.7-2.4 5.4-5.6 7.4-8.9 2.2-3.6 3.8-7.5 3.8-10.7 0-2-.3-4.1-.7-6.1-1.5-5.6-4.4-10.8-8.4-15.1M367.6 220c8.9-20.8 27.1-33.3 52.4-33.3 25 0 43.9 13.5 52.8 33.3 1.8 3.9 3.3 8 4.2 12.3H363.6c.9-4.3 2.4-8.4 4-12.3M341.1 156.6c-.8-4.3-2.5-8.3-4.9-11.8-5.5-8.1-14.6-13.4-25-13.4-12.4 0-23.7 7.9-28.2 19.7l-42.8 116.7-41.8-116.1c-4.5-11.8-14.7-20.3-27.6-20.3-13 0-23.1 8.5-27.6 20.3l-41.8 116.2-42.8-117.3c-4.5-11.9-15.2-19.2-27.6-19.2-16.9 0-31 14.1-31 31 0 4 1.1 7.9 2.3 11.3L75 366c4.5 11.8 16.4 19.1 28.8 19.1 13 0 23.7-8.5 28.2-20.3l38.9-108.3 38.9 108.3c4.5 11.8 15.3 20.3 28.2 20.3 12.4 0 24.2-7.3 28.8-19.1l22-58.2 7.1-18.7 8.8-23.4 34.3-90.8.5-1.2c1.2-3.4 2.3-7.3 2.3-11.3-.1-2-.3-3.9-.7-5.8M1152.5 122h1.1c.8 0 1.6.1 2.1.4.6.3 1.2.8 1.7 1.5.6.7 1.3 1.9 2.1 3.4l1.8 3.5h5.4l-2.5-4.3c-1-1.7-1.7-2.8-2.3-3.5-.6-.7-1.3-1.2-2.2-1.6 1.8-.2 3.2-.9 4.2-2s1.5-2.4 1.5-3.9c0-1.2-.3-2.3-.8-3.2s-1.3-1.6-2.4-2.1-2.4-.8-4-.8h-10.1v21.4h4.4V122zm0-9.3h3.9c1.5 0 2.6.2 3.4.7.7.5 1.1 1.2 1.1 2 0 1.2-.4 2.1-1.2 2.5-.8.4-2 .6-3.6.6h-3.5v-5.8h-.1z"
                      ></path>
                      <path
                        class="st0"
                        fill="#01b1af"
                        d="M1139.7 130.1c1.8 3.1 4.3 5.5 7.4 7.4 3.1 1.8 6.5 2.7 10.1 2.7 1.6 0 3.1-.2 4.6-.6 1.9-.4 3.8-1.1 5.5-2.1 3.1-1.8 5.6-4.3 7.4-7.4s2.7-6.5 2.7-10.1c0-2.8-.5-5.4-1.5-7.8-1-2.5-2.5-4.6-4.4-6.5s-4-3.3-6.5-4.4c-2.5-1-5-1.5-7.8-1.5-3.7 0-7 .9-10.1 2.7s-5.6 4.3-7.4 7.4c-1.8 3.1-2.7 6.5-2.7 10.1 0 2.4.5 4.7 1.3 6.9.4 1 .8 2.1 1.4 3.2m3.5-18.3c1.5-2.5 3.4-4.5 5.9-5.9s5.2-2.2 8.1-2.2 5.7.7 8.1 2.2c2.5 1.4 4.4 3.4 5.9 5.9 1.4 2.5 2.2 5.2 2.2 8.2 0 2.9-.7 5.7-2.2 8.1-1.4 2.5-3.4 4.5-5.9 5.9-2.5 1.5-5.2 2.2-8.2 2.2-.2 0-.3-.1-.5-.1-2.7-.1-5.3-.7-7.6-2.1-2-1.2-3.6-2.8-5-4.6-.3-.4-.7-.8-1-1.3-1.5-2.5-2.2-5.2-2.2-8.2.2-2.9.9-5.6 2.4-8.1"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li className="facebook">
                  <a
                    className="linked_logo facebook_logo"
                    href="https://facebook.com/HighburySchool"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                    >
                      <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12V24h6.116c.73 0 1.323-.593 1.323-1.325V1.325C24 .593 23.407 0 22.675 0z" />
                    </svg>
                  </a>
                </li>
                <li
                  className={
                    searchExpanded ? 'search search-expanded' : 'search'
                  }
                >
                  <form action="/search/" method="get">
                    <button type="submit" onClick={(e) => searchOnClick(e)}>
                      {searchExpanded ? '' : ''}
                    </button>
                    <input
                      name="term"
                      id="Search"
                      placeholder="Enter your search here"
                    ></input>
                  </form>
                </li>
                <li className="email">
                  <a href="mailto:admin@highbury.calderdale.sch.uk">
                    <span className="far fa-envelope"></span>
                  </a>
                </li>
              </ul>
            </div>

            {!websiteNavLoading ? (
              <div className="website-nav-inner-wrap">
                {isMobile ? (
                  /* mobile menu */
                  <div>
                    <div
                      className="menuToggler"
                      onClick={() => setToggleMobileNav(!toggleMobileNav)}
                    >
                      {!toggleMobileNav ? (
                        <span className="fas fa-bars"></span>
                      ) : (
                        <span className="fas fa-times"></span>
                      )}
                    </div>
                  </div>
                ) : (
                  /* desktop menu */

                  <ul className="navigation">
                    <li className="headerLink">
                      <Link
                        to="/"
                        activeClassName="active"
                        className="parentLink"
                      >
                        <span className="fas fa-home"></span>
                      </Link>
                    </li>
                    <ReturnChildNavItems WebsiteNavData={websiteNavData} />
                  </ul>
                )}
              </div>
            ) : (
              <div className="website-nav-inner-wrap"></div>
            )}
          </div>
        </div>
        {isMobile && (
          <div
            className={
              toggleMobileNav ? 'toggledMobile newMobile' : 'notToggled'
            }
          >
            <ReactNestedMenu
              menuData={websiteNavData || []}
              navParentClassname="dropdown vertical menu nested"
              navTopLevelParentClassname="navigation vertical menu"
              navChildClassname="headerLink child"
              linkTransformer={linkTransformer}
            />
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
